body {
  font-family: 'Lato';
  color: #7b7c7e;
  line-height: 1.5rem;
  font-size: 0.9375rem;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  text-transform: none;
}

.btn {
  color: #fff;
  font-size: 14px;
  font-family: 'Arial';
  padding: .55rem 2.5rem;
  border-radius: 0.2rem;
  font-weight: bold;

  &:hover {
    color: #fff;
  }
}

.nav-link {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* TODO moze dać do style.php */
a {
  color: #39a1f1;
}

.header h5 {
  font-size: 16px;
}

#nav  {
  //padding:inherit;
}

.header-up {
  background-color: #e4e5e7;
  padding: 0.70rem 0;
  font-size: 0.9rem;
  margin-bottom: 0.6rem;

  .links  {
    margin-bottom: 0;
    float: left;
    display: contents;

    .list-inline-item {
      margin-right: 1.5rem;
    }

    .header-mail-1  {
      margin-left: 0;
    }
  }

  p   {
    margin-bottom: 0;
  }
  #langs  {
    float: right;
    margin-bottom: 0;
  }

  a   {
    color: inherit;
  }

  .fa   {
    padding-right: 10px;
  }
}

.page .header-up  {
  margin-bottom: 0;
}

#header {
  .navbar-brand {
    background-color: #fff;
    padding-top: inherit;
    padding-left: 100rem;
    margin-left: -100rem;
    padding-bottom: 1rem;
  }

  .menu-title {
    width: 100%;
    padding: 1rem 1rem 1rem 2rem;
    margin: 1rem 0;
    color: #fff;
    position: relative;

    &:after {
      position: absolute;
      padding-left: 100rem;
      margin-left: -100rem;
      height: 100%;
      width: 10px;
      top: 0;
      left: 0;
      content: "";
    }

    h5  {
      margin-bottom: 0;
    }
  }
}

#box-column {
  margin: 2rem 0;
  padding: 2rem 1rem 2rem 2rem;
  color: #fff;
  min-height: 200px;
  font-size: 1rem;

  .box-column-text  {
    opacity: 0.9;

    ul  {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      li  {
        padding: 0.4rem 0;
      }
    }

    .fa {
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }

    a   {
      color: #fff;
    }
  }

  h3 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.box-column-2 {
  margin: 2rem 0 2rem 0;
}

.main-row > .col-md-8 {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    content: "";
    box-shadow: inset 7px 0 14px -7px rgba(0,0,0,0.2);
    display: none;
  }
}

#navbarNav  {

  .menu {
    width: 100%;
    padding-right: 0;

    .nav-item {
      border-bottom: 1px dotted #beb9b9;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:last-child  {
        border-bottom: none;
      }
    }


    .active, .nav-item:hover {
      background: #faf8f8;

      .nav-link {

        &:before {
          background-color: #e3824f;
          color:rgba(255, 255, 255, 0.8);

        }
      }

      .dropdown-menu {
        display: block !important;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        .nav-link {
          padding-left: 4rem;

          &:before {
            left: 36px;
          }
        }
      }
    }

    .nav-link {
      text-transform: uppercase;
      font-size: 0.80rem;
      font-family: 'Montserrat';
      padding-right: 0.65rem;
      padding-left: 3rem;
      position: relative;

      &:before {
        background-color: #e5e5df;
        position: absolute;
        font-family: "FontAwesome";
        content: "\f105";
        width: 15px;
        height: 15px;
        left: 20px;
        top: 13px;
        color:rgba(0, 0, 0, 0.5);
        text-align: center;
        vertical-align: middle;
        border-radius: 2px;
        display: table;
        line-height: 15px;
        transition: all 0.3s ease-in-out;
      }

    }

    > li > .nav-link {
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  .dropdown-menu  {
    padding: 0;
    border: none;
  }

  .dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar {

  .menu {

    .nav-link[data-toggle].collapsed:after {
      content: "▾";
    }
    .nav-link[data-toggle]:not(.collapsed):after {
      content: "▴";
    }
  }

  .links  {
    color: #7d7d7d;
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;

    .fa   {
      padding-right: 10px;
    }

    a   {
      color: inherit;
    }

    p   {
      margin-bottom: 0;
    }

    .list-inline-item:first-child {
      margin-right: 2rem;
    }

  }
}
/*
.dropdown:hover>.dropdown-menu {
  display: block;
}
*/
#langs {
  margin-left: 1rem;

  li {
    display: inline-block;
    margin-right: 0.4rem;

  }
  .flag-icon {
    border: 1px solid #cecece;
    line-height: 0.9rem;

    :before {
    }
  }
  .lang-code  {
    display: none;
  }
}

/*
.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown:hover .dropdown-menu {
  max-height: 200px;
  opacity: 1;
}
*/
//.navbar-expand-lg .navbar-nav .dropdown-menu  {
//  display: block;
//}



.under-slider  {
  background-color: #f7f8fa;
  padding: 3.8rem 0 2.8rem 0;
  border-bottom: 1px solid #d9dee2;

  .section-inner  {
    display: block;
    justify-content: space-between;
    align-items: center;

    p   {
      margin-bottom: 0;
    }

    .btn {
      margin: 0.2rem 0.4rem;
    }
  }

  .under-slider-text  {
    margin-bottom: 1rem;
  }
}
/**
  Columny
 */
.page-sidebar-column  {

  #content  {
    width: 100%;
    display: inline-block;
  }

  #sidebar-column {
    width: 100%;
    display: inline-block;
  }
}
.sidebar-column-left  {

  #sidebar-column {
    margin-left: 0;
    margin-right: 5%;
  }
}

#main {
  padding: 3rem 0 3rem 0;

  p {
    color: #8e8e90;
    padding-bottom: 0.5rem;
  }

  .content {

    h2 {
      padding-bottom: 1.5rem;

    }
  }

  #content  {
    padding-left: 0;
  }
}

.page #main {
  padding-top: 2rem;
}

/**
#parallax
 */
#parallax {
  background-position: center;
  color: #fff;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;

  p {
    color: #fff;
  }

  .container {
    padding-top: 4rem;
    padding-bottom: 3rem;
    position: relative;
  }

  &:before  {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #d99777;
  }
}

.bg-gray  {
  background-color: #eef0f3;
}

.section-home-contact-data  {
  font-size: 18px;

  .itp-col {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  a {
    color: inherit;
  }
}

.parallax-window {
  min-height: 250px;
  background: transparent;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

#home-footer-box  {
  background-color: #f8f8f9;
  padding: 5rem 5% 3rem 5%;
  margin: 8rem 0 2rem 0;
  position: relative;
  text-align: center;

  h4 {
    text-transform: none;
    margin-bottom: 1.5rem;
  }

  .btn {
    position: absolute;
    bottom: -16px;
    text-transform: uppercase;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: max-content;
    width: -moz-max-content;
  }

  .home-footer-box-ico  {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
    display: table;
    margin: 0 auto;
    vertical-align: middle;

    .fa {
      color: #fff;
      font-size: 3.5rem;
      padding-top: 2.2rem;
    }
  }
}

/**
sidebar
 */
.sidebar  {
  padding-left: 0;

  .box  {
    list-style: none;
    margin-bottom: 2rem;
  }

  .box-title  {
    margin-bottom: 1rem; // nie wiem do czego to było
    font-size: 1.4rem;
  }
}

#footer {
  background: #e4e5e7;
  color: #656668;
  position: relative;


  .footer-column  {
    padding: 2rem 0 1rem 0;

    .box-title  {
      font-size: 1rem;
      margin-bottom: 2.5rem;
    }

  }

  #footer-foo {
    padding: 3rem 0 1rem 0;
    position: static;
    top: 150px;
    max-width: 400px;

    p   {
      margin-bottom: 0;
    }

    a   {
      color: inherit;

      &:hover {
        color: #fff;
      }
    }
  }
}

/**
#cookies
 */
#cookies  {
  background-color: #eef0f3;
  padding: 20px 0 10px 0;
  position: fixed;
  bottom: 0;
  color: #6f7074;
  z-index: 10;

  .container-fluid  {
    max-height: 180px;
    overflow: auto;
  }

  .closecookies {
    float: right;
    max-width: 200px;
    font-size: 12px;
    white-space: normal;
    padding: .5rem 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.4rem;

  }

  p   {
    font-size: 0.7rem;
    line-height: 1rem;
  }
}

/**
  Facebook
 */
#slide-box-fblike {cursor: pointer; position: fixed; right: -326px; top: 126px; z-index: 15;}
.no-touch #slide-box-fblike {transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0.4s;}
.no-touch #slide-box-fblike:hover {right: 0; transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0s; z-index: 15;}
#slide-box-fblike .inside {background: none repeat scroll 0 0 #FFFFFF; border: 2px solid #39579B; height: 250px; padding: 10px; position: relative; width: 326px; z-index: 2;}
#slide-box-fblike .belt {background: url("../images/bt_social.png") repeat scroll 0 0 #3B5999; border-radius: 3px 0 0 3px; color: #FFFFFF; font-family: Verdana; font-size: 1px; font-weight: bold; height: 49px; left: -47px; position: absolute; top: 0; width: 47px; z-index: 0;}
#slide-box-fblike:hover .belt {background-position: 0 -49px;}

/**
.form-wrap
 */
.form-wrap  {

  .form-control {
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    padding-top: 0.4rem ;
    padding-bottom: 0.4rem ;
    border-radius: 0;
  }

  #captcha  {
    //margin-bottom: 0.6rem;
    max-height: 2.37rem;
    border-radius: 0.25rem;
    width: 100%;
  }

  label.small {
    line-height: 1.1rem;
  }
}

#form-fast  {

  .form-control {
    background-color: #dedfe1;
  }

}

/**
#slider-wrap-20
 */
#wrapper  {
  overflow-x: hidden;
}
.carousel-item-background-image {
  //height: 100vh;
  min-height: 40vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-home  {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}


.carousel-control {
  background-color: rgba(255,255,255,0.4);
  padding: 6px 18px;
}
.carousel .desc-wrap  {
  color: #fff;

  bottom: 0;
  display: none;
  text-align: left;
  left: 0;
  right: 0;
  padding-bottom: 0.8rem;

  h3 {
    font-size: 1rem;
  }

  p   {
    margin-bottom: 0.5rem;
  }

  &:before {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    opacity: 0.9;
    bottom: 0;
    z-index: -1;
  }
}

.carousel-caption-background  {
  padding-right: 3rem;
  padding-left: 3rem
}

.carousel-indicators {
  max-width: 100%;
  margin: 0;

  &.active {
    background: none;
  }

  li {
    width: auto;
    height: auto;
    border: 1px solid #cecdcd;
    background: none;
    border-radius: 20px;

    span {
      background-color: #cecdcd;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: block;
      margin: 1px;

    }
  }
}

.box-footer {

  .menu {

    .dropdown-menu  {
      display: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .nav-item {
      border-bottom: 1px dashed #717172;

      &:last-child {
        border-bottom: none;
      }


    }

    .active, .nav-item:hover {
      .nav-link {
        background-color: #dedfe1;
      }
    }

    .nav-link {
      padding: 0.35rem 1rem;
      color: #737373;
      //position: relative;

      &:before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
        font-size: 0.8rem;
        vertical-align: top;
        font-family: "FontAwesome";
        content: "\f105";
      }

      &:hover {
        color: #484848;
      }
    }


  }

  .fa {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 1.4rem;
    padding-top: 8px;

    &:hover  {
      opacity: 0.8;
    }
  }
  .fa-facebook  {
    background-color: #1c6db1;
  }
  .fa-twitter  {
    background-color: #39a1f1;
  }
  .fa-youtube  {
    background-color: #9c9c9e;
  }

}

/**
Page
 */
#top-section  {
  min-height: 150px;
  background-position: center;
  background-size: cover;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #4ca6ec;
  }

  .titlewrap  {
    position: relative;
    z-index: 10;
    position: relative;
    padding-bottom: 14px;

    &:after {
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 150px;
      height: 3px;
      background-color: #fff;
      content: "";
    }
    h2  {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }


}

/**
  breadcrumb
 */
.breadcrumb-wrap  {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1rem;

  .breadcrumb {
    background: none;
    margin-bottom: 0;
    border-radius: 0;
    color: #505055;

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.8rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: "›";
      font-size: 1.5rem;
      line-height: 1.2rem;
      padding-right: 0.8rem;
    }

    .arrow  {display: none}

    .fa   {
      padding: 0 0.5rem 0 0;
    }

    a   {
      color: #505055;
    }
  }
}

/**
uklad1 sidebox
 */
.sidebar-column {

  .box  {
    margin-bottom: 4rem;
  }

  .contact-icon {

    ul {
      list-style: none;
      padding-left: 0;

      li {
        background-color: #f7f8fa;
        font-size: 0.9rem;
        width: 100%;
        vertical-align: middle;
        border-top: 1px solid #dedfe1;
        border-right: 1px solid #dedfe1;

        display: flex;
        align-items: center;

        &:last-child  {
          border-bottom: 1px solid #dedfe1;
        }

        span {
          padding: 10px 16px;
        }

        img {
          padding: 16px 6px;
          float: left;
          min-height: 72px;

        }
      }
    }

  }
}

/**
  uklad3
 */
#left-column  {
  background-color: #fff;
  height: 100%;

  .menu-title {
    display: none;
  }
}

.main-row {
  > .col-md-4 {
    background-color: #fff;
    position:relative;
    z-index: 1;

    &:after {
      background-color: #fff;
      position: absolute;
      content: "";
      padding-left: 100rem;
      margin-left: -100rem;
      height: 7rem;
      width: 10px;
      bottom: -6rem;
      right: 0;
      display: none;
    }
  }
}

.page {
  .titlewrap  {
    margin-left: 0;
    margin-top: 2rem;
    border-bottom: 5px solid #d8d9da;


    h2  {
      padding-bottom: 2rem;
      margin-bottom: -5px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 190px;
        height: 5px;
      }

    }
  }
}



/**
#form-contact-wrap
 */
#form-contact-wrap {

  background-color: #f7f8fa;
  border: 1px solid #d9dee2;
  padding: 2.5rem 2rem;
  font-size: 0.95rem;

  .form-control {
    border-radius: 0;
    font-size: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .form-group-rodo  {
    padding-top: 0;
  }

  .captcha  {
    border: 1px solid #d9dee2;
  }

  .btn  {
    border-radius: 0;
  }
}


/**
  news-listing
 */
.news-listing {

  .item {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e5e5;


  }


  .item:last-child  {
    border: none;
  }

  .title  {
    font-size: 1.2rem;
    font-family: 'Lato';
    text-transform: none;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }

  .t_main {
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #8e8e90;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: .8rem;
    margin-bottom: .8rem;
  }

  .t_category {
    display: none;
  }

  .news-date  {
    color: #8e8e90;
    font-size: 0.9rem;
    float: left;
    margin-top: 0.1rem;
  }

  .news-more {
    padding: .40rem 1.5rem;
    float: right;
    font-weight: normal;
    font-family: 'Lato';
    font-size: 0.95rem;
  }
}

.news-single  {

  .news-date  {
    margin-bottom: 1rem;
  }

  .title  {
    margin-bottom: 1.5rem;
  }

  .thumbwrap  {
    margin-bottom: 1rem;
  }

  .t_category {
    display: none;
  }
}

/**
  gallery-wrap
 */
.gallery-wrap {
  justify-content: center;
  text-align: center;

  .item {
    margin-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;


    .img-wrap {
      position: relative;
    }

    .hover-mask {
      background: rgba(0,0,0,0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow:visible;
      transition: all 0.4s ease-in-out;

    }

    a:hover .hover-mask   {
      opacity: 1;
    }
  }
}


/**

  RWD

 */

@media (min-width: 320px) {

}

@media (min-width: 480px) {
  .carousel .desc-wrap  {
    display: block;
  }

  .carousel-item-background-image {
    min-height: 300px;
  }

}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .carousel-item-background-image {
    min-height: 320px;
  }
  .carousel  {

    .desc-wrap  {

      h3  {
      font-size: 1.5rem;
      }
    }
  }

  .under-slider {

    .section-inner  {
      display: flex;
    }
    .under-slider-text  {
      margin-bottom: 0;
    }
  }

  .section-home-contact-data {
    font-size: 18px;

    .itp-col {
      padding: 4rem 1rem 2.5rem 1rem;
    }
  }
  #top-section .titlewrap  h2  {
    font-size: 2rem;
  }

  #cookies .container-fluid {
    height: auto;
  }
  #left-column  {
    margin-top: -45px;

    .menu-title {
      display: block;
    }
  }

  .main-row > .col-md-8::after  {
    display: block;
  }

  .box-column-2 {
    margin-right: 2rem;
    margin-top: 4rem;
  }
  .main-row > .col-md-4::after  {
    display: block;
  }

  .carousel-home  {
    width: calc(50vw + 25.6334%);
    position: relative;
    left: 0;
    right: 50%;
    margin-left: 0;
    margin-right: -50vw;
  }

  #parallax {
    margin: 3rem auto;
  }

  .parallax-window {
    width: calc(50vw + 25.6334%);
    position: relative;
    left: 0;
    right: 50%;
    margin-left: 0;
    margin-right: -50vw;
  }

  .carousel-caption-background {
    padding-right: calc(50vw - 68%);
  }

  #footer #footer-foo {
    position: absolute;
  }

  #home-footer-box {
    margin: 8rem 0 2rem 3rem;
  }

  #navbarNav .menu  {
    padding-right: 2.8rem;
  }

  #main #content  {
    padding-left: 3rem;
  }

  .header-up .links .header-mail-1  {
    margin-left: 2rem;
  }

  #footer .footer-column  {
    padding: 5rem 0 1rem 0;
  }

  .page .titlewrap  {
    margin-left: 3rem;
  }

  .header-up {
    padding: 1.70rem 0;
  }

  #header .navbar-brand {
    padding-top: 2.4rem;
  }

  #nav  {
    padding: 0;
  }

}

@media (min-width: 992px) {

  .carousel-item-background-image {
    min-height: 400px;
  }
  .home #main .content .col-md-6:last-child {
    padding-left: 3rem;
  }
  #cookies p  {
    font-size: 0.9375rem;
  }

  #form-contact-wrap .form-group-rodo {
    padding-top: 2rem;
  }

}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {
  .carousel-item-background-image {
    min-height: 530px;
  }
}